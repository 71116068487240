export function HotelLogo({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 30">
      <g>
        <g>
          <g>
            <path
              fill={fillColor}
              d="M75.72,3.8l-3.19,8.14c-0.11,0.29-0.17,0.5-0.17,0.68c0,0.35,0.25,0.59,0.8,0.79h-4.11
                     c0.8-0.35,1.03-0.56,1.31-1.22l5.36-12.11l5.37,12.11c0.29,0.66,0.52,0.87,1.32,1.22h-4.11c0.54-0.21,0.8-0.44,0.8-0.79
                     c0-0.17-0.06-0.39-0.18-0.68L75.72,3.8z"
            />
            <path
              fill={fillColor}
              d="M7.35,1.4c-3.03,0-4.98,2.11-4.98,5.31c0,3.24,1.88,5.36,4.94,5.36
                     c2.85,0,4.21-1.97,4.21-1.97l-0.23,2.68c-2,0.59-2.91,0.77-4.32,0.77C2.64,13.55,0,10.54,0,6.81c0-3.88,2.91-6.69,6.94-6.69
                     c1.78,0,2.9,0.31,3.41,0.31c0.33,0,0.5-0.09,0.73-0.43v3.1C11.09,3.1,10.17,1.4,7.35,1.4"
            />
            <path
              fill={fillColor}
              d="M23.27,12.09c-2.38,0-4.22-2.34-4.22-5.38c0-3.01,1.86-5.22,4.38-5.22
                     c2.56,0,4.23,2.07,4.23,5.27C27.65,9.89,25.83,12.09,23.27,12.09 M23.35,0.11c-3.84,0-6.48,2.7-6.48,6.62
                     c0,3.87,2.81,6.82,6.48,6.82c3.66,0,6.45-2.95,6.45-6.82C29.8,2.81,27.17,0.11,23.35,0.11"
            />
            <path
              fill={fillColor}
              d="M45.49,9.54V2.56c0-1.39-0.14-1.62-1.13-1.95h3.84c-0.9,0.43-0.96,0.58-0.96,1.98v11.08
                     l-9.88-9.39v7.25c0,1.26,0.15,1.5,1.22,1.89h-4.11c0.79-0.29,1.1-0.62,1.1-1.87V0.1L45.49,9.54z"
            />
            <path
              fill={fillColor}
              d="M62.76,3.76c0,2.67-2.32,3.1-2.32,3.1l2.49,3.02c1.11,1.4,2.26,2.82,3.37,3.52h-2.77
                     l-5.46-6.7c1.32-0.27,2.54-1.06,2.54-2.58c0-1.2-0.97-2.18-2.42-2.18c-0.71,0-1.23,0.16-1.23,0.16v8.6c0,0.89,0.01,2.37,1.2,2.72
                     l-0.33-0.01h-3.56l-0.31,0.01c1.25-0.35,1.16-1.84,1.16-2.72V3.38c0-0.89,0.15-2.4-0.96-2.73h4.55
                     C60.42,0.65,62.76,1.28,62.76,3.76"
            />
            <path
              fill={fillColor}
              d="M90.89,11.92h-0.95c-0.79,0-1.06-0.31-1.06-1.22V1.9h1.84c3.2,0,5.16,2.12,5.16,5.18
                     C95.87,10.09,93.81,11.92,90.89,11.92 M98.16,7.04c0-4.31-3.23-6.39-7.43-6.39h-4.82c1.09,0.38,1.14,0.66,1.14,1.9v9
                     c0,1.25-0.28,1.57-1.14,1.86h4.13C95.04,13.41,98.16,11.13,98.16,7.04"
            />
          </g>
          <g>
            <path
              fill={fillColor}
              d="M5.62,25.02c0-1.28,0-1.51-0.02-1.78c-0.02-0.29-0.1-0.43-0.29-0.47
                     c-0.09-0.03-0.2-0.03-0.3-0.03c-0.08,0-0.13-0.02-0.13-0.08c0-0.05,0.07-0.07,0.2-0.07c0.32,0,0.85,0.03,1.09,0.03
                     c0.21,0,0.71-0.03,1.03-0.03c0.11,0,0.18,0.02,0.18,0.07c0,0.07-0.05,0.08-0.13,0.08c-0.08,0-0.15,0.01-0.25,0.03
                     c-0.23,0.04-0.29,0.19-0.31,0.48c-0.02,0.27-0.02,0.5-0.02,1.78v1.48c0,0.82,0,1.48,0.03,1.84c0.03,0.23,0.08,0.38,0.34,0.41
                     c0.12,0.02,0.3,0.03,0.43,0.03c0.09,0,0.13,0.03,0.13,0.07c0,0.06-0.07,0.08-0.16,0.08c-0.55,0-1.08-0.03-1.31-0.03
                     c-0.19,0-0.72,0.03-1.06,0.03c-0.11,0-0.17-0.03-0.17-0.08c0-0.04,0.03-0.07,0.13-0.07c0.13,0,0.23-0.02,0.3-0.03
                     c0.17-0.03,0.22-0.18,0.24-0.42c0.04-0.35,0.04-1.02,0.04-1.83V25.02z"
            />
            <path
              fill={fillColor}
              d="M11.68,27.79c0.02,0.66,0.13,0.87,0.29,0.93c0.14,0.05,0.3,0.06,0.44,0.06
                     c0.09,0,0.14,0.02,0.14,0.07c0,0.07-0.08,0.08-0.19,0.08c-0.55,0-0.88-0.03-1.04-0.03c-0.08,0-0.47,0.03-0.91,0.03
                     c-0.11,0-0.18-0.01-0.18-0.08c0-0.05,0.05-0.07,0.13-0.07c0.11,0,0.26-0.01,0.38-0.04c0.22-0.07,0.25-0.31,0.26-1.04l0.08-4.98
                     c0-0.17,0.03-0.29,0.11-0.29c0.09,0,0.17,0.11,0.31,0.26c0.1,0.11,1.38,1.48,2.61,2.7c0.57,0.57,1.71,1.78,1.85,1.92h0.04
                     l-0.08-3.77c-0.01-0.51-0.08-0.67-0.29-0.76c-0.13-0.05-0.33-0.05-0.45-0.05c-0.1,0-0.13-0.03-0.13-0.08
                     c0-0.07,0.09-0.08,0.22-0.08c0.44,0,0.84,0.03,1.02,0.03c0.09,0,0.41-0.03,0.82-0.03c0.11,0,0.19,0.01,0.19,0.08
                     c0,0.05-0.05,0.08-0.15,0.08c-0.08,0-0.15,0-0.25,0.03c-0.24,0.07-0.3,0.24-0.31,0.72l-0.09,5.3c0,0.19-0.03,0.26-0.11,0.26
                     c-0.09,0-0.19-0.09-0.29-0.18c-0.53-0.5-1.61-1.61-2.48-2.47c-0.92-0.91-1.85-1.96-2-2.12h-0.03L11.68,27.79z"
            />
            <path
              fill={fillColor}
              d="M20.95,25.02c0-1.28,0-1.51-0.02-1.78c-0.02-0.29-0.08-0.42-0.36-0.48
                     c-0.07-0.02-0.21-0.03-0.33-0.03c-0.09,0-0.14-0.02-0.14-0.08c0-0.06,0.06-0.08,0.18-0.08c0.45,0,0.98,0.03,1.21,0.03
                     c0.26,0,0.79-0.03,1.29-0.03c1.05,0,2.45,0,3.37,0.96c0.42,0.44,0.82,1.14,0.82,2.14c0,1.06-0.45,1.87-0.92,2.35
                     c-0.39,0.4-1.26,0.99-2.82,0.99c-0.61,0-1.37-0.1-1.79-0.1c-0.19,0-0.72,0.03-1.06,0.03c-0.11,0-0.17-0.03-0.17-0.08
                     c0-0.04,0.03-0.07,0.13-0.07c0.13,0,0.23-0.02,0.3-0.03c0.17-0.03,0.21-0.22,0.24-0.46c0.04-0.35,0.04-1.02,0.04-1.79V25.02z
                      M22,25.94c0,0.89,0.01,1.54,0.02,1.7c0.01,0.21,0.03,0.55,0.09,0.64c0.11,0.16,0.44,0.34,1.1,0.34c0.86,0,1.43-0.17,1.93-0.61
                     c0.54-0.46,0.71-1.23,0.71-2.09c0-1.07-0.45-1.76-0.81-2.1c-0.77-0.74-1.73-0.84-2.39-0.84c-0.17,0-0.48,0.03-0.55,0.06
                     c-0.08,0.03-0.1,0.08-0.1,0.17C22,23.49,22,24.22,22,24.89V25.94z"
            />
            <path
              fill={fillColor}
              d="M30.52,25.02c0-1.28,0-1.51-0.02-1.78c-0.02-0.29-0.1-0.43-0.29-0.47
                     c-0.09-0.03-0.2-0.03-0.3-0.03c-0.08,0-0.13-0.02-0.13-0.08c0-0.05,0.07-0.07,0.2-0.07c0.32,0,0.85,0.03,1.09,0.03
                     c0.21,0,0.71-0.03,1.03-0.03c0.11,0,0.18,0.02,0.18,0.07c0,0.07-0.05,0.08-0.13,0.08c-0.08,0-0.15,0.01-0.25,0.03
                     c-0.23,0.04-0.29,0.19-0.31,0.48c-0.02,0.27-0.02,0.5-0.02,1.78v1.48c0,0.82,0,1.48,0.03,1.84c0.03,0.23,0.08,0.38,0.34,0.41
                     c0.12,0.02,0.3,0.03,0.43,0.03c0.09,0,0.13,0.03,0.13,0.07c0,0.06-0.07,0.08-0.16,0.08c-0.55,0-1.08-0.03-1.31-0.03
                     c-0.19,0-0.72,0.03-1.06,0.03c-0.11,0-0.17-0.03-0.17-0.08c0-0.04,0.03-0.07,0.13-0.07c0.13,0,0.23-0.02,0.3-0.03
                     c0.17-0.03,0.22-0.18,0.24-0.42c0.04-0.35,0.04-1.02,0.04-1.83V25.02z"
            />
            <path
              fill={fillColor}
              d="M36.44,26.74c-0.04,0-0.06,0.02-0.08,0.07l-0.45,1.2c-0.08,0.21-0.13,0.41-0.13,0.51
                     c0,0.15,0.08,0.26,0.34,0.26h0.13c0.1,0,0.13,0.02,0.13,0.07c0,0.07-0.05,0.08-0.14,0.08c-0.27,0-0.63-0.03-0.89-0.03
                     c-0.09,0-0.55,0.03-0.99,0.03c-0.11,0-0.16-0.02-0.16-0.08c0-0.05,0.03-0.07,0.1-0.07c0.08,0,0.19-0.01,0.26-0.02
                     c0.39-0.05,0.55-0.34,0.71-0.76l2.11-5.28c0.1-0.24,0.15-0.34,0.24-0.34c0.08,0,0.13,0.08,0.21,0.27
                     c0.2,0.46,1.55,3.89,2.08,5.17c0.32,0.76,0.56,0.87,0.74,0.92c0.13,0.03,0.25,0.03,0.35,0.03c0.07,0,0.11,0.01,0.11,0.07
                     c0,0.07-0.08,0.08-0.39,0.08c-0.3,0-0.92,0-1.59-0.02c-0.15-0.01-0.25-0.01-0.25-0.07c0-0.05,0.03-0.07,0.12-0.08
                     c0.06-0.02,0.12-0.09,0.08-0.19l-0.67-1.78c-0.02-0.04-0.04-0.06-0.08-0.06H36.44z M38.15,26.32c0.04,0,0.05-0.03,0.04-0.05
                     l-0.76-2.08c-0.01-0.03-0.02-0.08-0.04-0.08c-0.03,0-0.04,0.04-0.05,0.08l-0.77,2.07c-0.01,0.03,0,0.06,0.03,0.06H38.15z"
            />
            <path
              fill={fillColor}
              d="M44.24,27.79c0.02,0.66,0.13,0.87,0.29,0.93c0.14,0.05,0.3,0.06,0.44,0.06
                     c0.09,0,0.14,0.02,0.14,0.07c0,0.07-0.08,0.08-0.19,0.08c-0.55,0-0.88-0.03-1.04-0.03c-0.08,0-0.47,0.03-0.91,0.03
                     c-0.11,0-0.18-0.01-0.18-0.08c0-0.05,0.05-0.07,0.13-0.07c0.11,0,0.26-0.01,0.38-0.04c0.22-0.07,0.25-0.31,0.26-1.04l0.08-4.98
                     c0-0.17,0.03-0.29,0.11-0.29c0.09,0,0.17,0.11,0.31,0.26c0.1,0.11,1.38,1.48,2.61,2.7c0.57,0.57,1.71,1.78,1.85,1.92h0.04
                     l-0.08-3.77c-0.01-0.51-0.08-0.67-0.29-0.76c-0.13-0.05-0.33-0.05-0.45-0.05c-0.1,0-0.13-0.03-0.13-0.08
                     c0-0.07,0.09-0.08,0.22-0.08c0.44,0,0.84,0.03,1.02,0.03c0.09,0,0.41-0.03,0.82-0.03c0.11,0,0.19,0.01,0.19,0.08
                     c0,0.05-0.05,0.08-0.15,0.08c-0.08,0-0.15,0-0.25,0.03c-0.24,0.07-0.3,0.24-0.31,0.72l-0.09,5.3c0,0.19-0.03,0.26-0.11,0.26
                     c-0.09,0-0.19-0.09-0.29-0.18c-0.53-0.5-1.61-1.61-2.48-2.47c-0.92-0.91-1.85-1.96-2-2.12h-0.03L44.24,27.79z"
            />
            <path
              fill={fillColor}
              d="M53.85,26.74c-0.04,0-0.06,0.02-0.08,0.07l-0.45,1.2c-0.08,0.21-0.13,0.41-0.13,0.51
                     c0,0.15,0.08,0.26,0.34,0.26h0.13c0.1,0,0.13,0.02,0.13,0.07c0,0.07-0.05,0.08-0.14,0.08c-0.27,0-0.63-0.03-0.89-0.03
                     c-0.09,0-0.55,0.03-0.99,0.03c-0.11,0-0.16-0.02-0.16-0.08c0-0.05,0.03-0.07,0.1-0.07c0.08,0,0.19-0.01,0.26-0.02
                     c0.39-0.05,0.55-0.34,0.71-0.76l2.11-5.28c0.1-0.24,0.15-0.34,0.24-0.34c0.08,0,0.13,0.08,0.21,0.27
                     c0.2,0.46,1.55,3.89,2.08,5.17c0.32,0.76,0.56,0.87,0.74,0.92c0.13,0.03,0.25,0.03,0.35,0.03c0.07,0,0.11,0.01,0.11,0.07
                     c0,0.07-0.08,0.08-0.39,0.08c-0.3,0-0.92,0-1.59-0.02c-0.15-0.01-0.25-0.01-0.25-0.07c0-0.05,0.03-0.07,0.12-0.08
                     c0.06-0.02,0.12-0.09,0.08-0.19L55.8,26.8c-0.02-0.04-0.04-0.06-0.08-0.06H53.85z M55.56,26.32c0.04,0,0.05-0.03,0.04-0.05
                     l-0.76-2.08c-0.01-0.03-0.02-0.08-0.04-0.08c-0.03,0-0.04,0.04-0.05,0.08l-0.77,2.07c-0.01,0.03,0,0.06,0.03,0.06H55.56z"
            />
            <path
              fill={fillColor}
              d="M61.18,25.02c0-1.28,0-1.51-0.02-1.78c-0.02-0.29-0.08-0.42-0.36-0.48
                     c-0.07-0.02-0.21-0.03-0.33-0.03c-0.09,0-0.14-0.02-0.14-0.08c0-0.06,0.06-0.08,0.18-0.08c0.45,0,0.98,0.03,1.21,0.03
                     c0.34,0,0.83-0.03,1.22-0.03c1.05,0,1.43,0.35,1.56,0.48c0.18,0.18,0.42,0.55,0.42,1c0,1.19-0.87,2.03-2.07,2.03
                     c-0.04,0-0.13,0-0.18-0.01c-0.04-0.01-0.1-0.02-0.1-0.08c0-0.07,0.06-0.09,0.24-0.09c0.47,0,1.13-0.53,1.13-1.39
                     c0-0.28-0.03-0.84-0.5-1.26c-0.3-0.28-0.65-0.33-0.84-0.33c-0.13,0-0.25,0.01-0.33,0.03c-0.04,0.02-0.07,0.07-0.07,0.16v3.36
                     c0,0.77,0,1.44,0.04,1.8c0.03,0.24,0.08,0.42,0.33,0.45c0.12,0.02,0.3,0.03,0.43,0.03c0.09,0,0.13,0.03,0.13,0.07
                     c0,0.06-0.07,0.08-0.16,0.08c-0.55,0-1.08-0.03-1.3-0.03c-0.19,0-0.72,0.03-1.06,0.03c-0.11,0-0.17-0.03-0.17-0.08
                     c0-0.04,0.03-0.07,0.13-0.07c0.13,0,0.23-0.02,0.3-0.03c0.17-0.03,0.21-0.22,0.24-0.46c0.04-0.35,0.04-1.02,0.04-1.79V25.02z"
            />
            <path
              fill={fillColor}
              d="M67.55,25.76c0-1.44,0.95-3.3,3.47-3.3c2.09,0,3.4,1.22,3.4,3.14c0,1.93-1.35,3.45-3.48,3.45
                     C68.52,29.06,67.55,27.25,67.55,25.76z M73.24,25.92c0-1.88-1.08-3.05-2.46-3.05c-0.97,0-2.07,0.54-2.07,2.63
                     c0,1.75,0.97,3.14,2.58,3.14C71.88,28.63,73.24,28.35,73.24,25.92z"
            />
            <path
              fill={fillColor}
              d="M79.01,26.5c0,1.08,0,1.66,0.18,1.81c0.14,0.13,0.36,0.19,1.02,0.19
                     c0.45,0,0.77-0.01,0.98-0.24c0.1-0.11,0.2-0.34,0.22-0.5c0.01-0.08,0.03-0.13,0.09-0.13c0.06,0,0.07,0.04,0.07,0.14
                     c0,0.09-0.06,0.72-0.13,0.97c-0.05,0.19-0.09,0.23-0.53,0.23c-1.21,0-1.77-0.05-2.45-0.05c-0.19,0-0.72,0.03-1.06,0.03
                     c-0.11,0-0.17-0.03-0.17-0.08c0-0.04,0.03-0.07,0.13-0.07c0.13,0,0.23-0.02,0.3-0.03c0.17-0.03,0.21-0.22,0.24-0.46
                     c0.04-0.35,0.04-1.02,0.04-1.79v-1.48c0-1.28,0-1.51-0.02-1.78c-0.02-0.29-0.08-0.42-0.36-0.48c-0.07-0.02-0.17-0.03-0.28-0.03
                     c-0.09,0-0.14-0.02-0.14-0.08c0-0.06,0.06-0.08,0.19-0.08c0.39,0,0.92,0.03,1.15,0.03c0.2,0,0.82-0.03,1.14-0.03
                     c0.12,0,0.18,0.02,0.18,0.08c0,0.06-0.05,0.08-0.15,0.08c-0.09,0-0.23,0.01-0.33,0.03c-0.23,0.04-0.29,0.19-0.31,0.48
                     c-0.02,0.27-0.02,0.5-0.02,1.78V26.5z"
            />
            <path
              fill={fillColor}
              d="M84.83,25.02c0-1.28,0-1.51-0.02-1.78c-0.02-0.29-0.1-0.43-0.29-0.47
                     c-0.09-0.03-0.2-0.03-0.3-0.03c-0.08,0-0.13-0.02-0.13-0.08c0-0.05,0.07-0.07,0.2-0.07c0.32,0,0.85,0.03,1.09,0.03
                     c0.21,0,0.71-0.03,1.03-0.03c0.11,0,0.18,0.02,0.18,0.07c0,0.07-0.05,0.08-0.13,0.08c-0.08,0-0.15,0.01-0.25,0.03
                     c-0.23,0.04-0.29,0.19-0.31,0.48c-0.02,0.27-0.02,0.5-0.02,1.78v1.48c0,0.82,0,1.48,0.03,1.84c0.03,0.23,0.08,0.38,0.34,0.41
                     c0.12,0.02,0.3,0.03,0.43,0.03c0.09,0,0.13,0.03,0.13,0.07c0,0.06-0.07,0.08-0.16,0.08c-0.55,0-1.08-0.03-1.31-0.03
                     c-0.19,0-0.72,0.03-1.06,0.03c-0.11,0-0.17-0.03-0.17-0.08c0-0.04,0.03-0.07,0.13-0.07c0.13,0,0.23-0.02,0.3-0.03
                     c0.17-0.03,0.22-0.18,0.24-0.42c0.04-0.35,0.04-1.02,0.04-1.83V25.02z"
            />
            <path
              fill={fillColor}
              d="M89.94,28.84c-0.13-0.06-0.16-0.1-0.16-0.29c0-0.46,0.03-0.97,0.04-1.1
                     c0.01-0.13,0.03-0.22,0.1-0.22c0.08,0,0.08,0.08,0.08,0.14c0,0.11,0.03,0.29,0.08,0.43c0.18,0.62,0.68,0.85,1.2,0.85
                     c0.76,0,1.13-0.51,1.13-0.96c0-0.41-0.13-0.81-0.82-1.35l-0.39-0.3c-0.92-0.72-1.24-1.31-1.24-1.99c0-0.92,0.77-1.59,1.94-1.59
                     c0.55,0,0.9,0.08,1.12,0.14c0.08,0.02,0.12,0.04,0.12,0.1c0,0.11-0.03,0.35-0.03,1.01c0,0.19-0.03,0.25-0.09,0.25
                     c-0.06,0-0.08-0.05-0.08-0.15c0-0.08-0.04-0.34-0.22-0.55c-0.13-0.16-0.37-0.41-0.92-0.41c-0.62,0-1,0.36-1,0.87
                     c0,0.39,0.19,0.68,0.89,1.21l0.24,0.18c1.01,0.78,1.38,1.34,1.38,2.14c0,0.49-0.19,1.07-0.79,1.46
                     c-0.42,0.27-0.89,0.34-1.34,0.34C90.68,29.06,90.31,29,89.94,28.84z"
            />
          </g>
        </g>
        <g>
          <path
            fill={fillColor}
            d="M97.8,1.31c0-0.61,0.49-1.09,1.1-1.09c0.63,0,1.1,0.47,1.1,1.09c0,0.6-0.49,1.09-1.1,1.09
                 C98.27,2.4,97.8,1.93,97.8,1.31z M98.9,2.27c0.53,0,0.92-0.42,0.92-0.96c0-0.52-0.4-0.96-0.92-0.96c-0.53,0-0.93,0.41-0.93,0.96
                 C97.98,1.83,98.39,2.27,98.9,2.27z M98.74,1.41v0.13c0,0.27,0.01,0.29,0.03,0.3c0.01,0.01,0.04,0.02,0.11,0.02
                 c0.02,0,0.02,0.01,0.03,0.02c0,0.02-0.01,0.03-0.03,0.03h-0.05c-0.02,0-0.12-0.01-0.19-0.01c-0.08,0-0.16,0.01-0.18,0.01h-0.05
                 c-0.01,0-0.03-0.01-0.03-0.02s0.01-0.02,0.03-0.02c0.1,0,0.11-0.01,0.12-0.09c0.01-0.06,0.01-0.14,0.01-0.33V1.16
                 c0-0.18,0-0.26,0-0.33c-0.01-0.07-0.04-0.08-0.13-0.09c-0.02,0-0.02-0.01-0.02-0.02c0-0.01,0.01-0.02,0.03-0.02h0.05
                 c0.02,0,0.12,0.01,0.19,0.01c0.06,0,0.1-0.01,0.2-0.01c0.22,0,0.3,0.03,0.35,0.07c0.05,0.04,0.11,0.1,0.11,0.22
                 c0,0.11-0.04,0.21-0.21,0.36c0.15,0.17,0.26,0.32,0.35,0.42c0.05,0.06,0.1,0.1,0.13,0.1c0.02,0,0.03,0.01,0.03,0.03
                 c0,0.01-0.01,0.02-0.03,0.02h-0.07c-0.07,0-0.15,0-0.2-0.03c-0.07-0.04-0.13-0.11-0.22-0.23C99,1.55,98.94,1.45,98.91,1.41H98.74z
                  M98.74,1.29c0.03,0.02,0.08,0.03,0.11,0.03c0.04,0,0.08,0,0.12-0.02c0.06-0.04,0.09-0.12,0.09-0.24c0-0.17-0.1-0.29-0.25-0.29
                 c-0.03,0-0.06,0-0.07,0.01V1.29z"
          />
        </g>
      </g>
    </svg>
  );
}
